
<template>
<div class="py-4">
    <div v-if="!isLoading">
        <div class="row mb-4 gy-5 justify-content-center">
            <div class="order-2 col-lg-5"> 
                <div v-if="!isMobile" class="position-sticky" style="top:80px;">
                    <div class="mb-4 d-flex align-items-center justify-content-center">
                        <button :class="cart.interval == 'year' ? 'btn-primary' : 'btn-outline-primary'"
                        @click="intervalChange('year')" type="button" class="btn rounded-0">Yearly</button>
                        <span class="mx-3">OR</span>
                        <button :class="cart.interval == 'month' ? 'btn-primary' : 'btn-outline-primary'"
                        @click="intervalChange('month')" type="button" class="btn rounded-0">Monthly</button>
                    </div>  
                    <checkout-device-cart 
                    :cart="cart" :canSumbmit="canSumbmit"/>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="row mb-5 justify-content-center">
                    <div class="col-12">
                        <h4 class="">Choose Device</h4>
                        <hr class="mb-4 mt-2" /> 
                        <div class="row g-4 justify-content-center">
                            <div class="col-12">   
                                <div v-for="(datum, datumIndex) in data" :key="datumIndex" class="row gy-2 g-4 mb-4">
                                    <div class="col-12 d-flex">
                                        <h6 class="text-decoration-underline me-3">Device #{{datumIndex+1}}</h6>
                                        <h6 v-if="datumIndex > 0" @click="removePhone(datumIndex)" class="text-decoration-underline fw-normal text-danger cursor-pointer">Remove</h6>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="form-label mb-1">Device <span class="text-danger">*</span></label>
                                        <select  @change="categoryChange(datumIndex)" class="form-select" v-model="datum.category_id"
                                        name="type" placeholder="--select make--">
                                            <option disabled value="">--select device --</option>
                                            <option v-for="(category, caIndex) in categories" :key="datumIndex+caIndex"
                                            :value="category.id">{{category.title}}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="form-label mb-1">Make <span class="text-danger">*</span></label>
                                        <select :disabled="fetchingCategory" @change="brandChange(datumIndex)" class="form-select" v-model="datum.brand"
                                        name="type" placeholder="--select make--">
                                            <option disabled value="">--select make--</option>
                                            <option v-for="(brand, brIndex) in brands(datumIndex)" :key="datumIndex+brIndex"
                                            :value="brand">{{brand.title}}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 flex-grow-1">
                                        <label class="form-label mb-1">Model <span class="text-danger">*</span></label>
                                        <select @change="deviceChange(datumIndex)" class="form-select" v-model="datum.device"
                                        name="type" placeholder="--select make--">
                                            <option disabled value="">--select make--</option>
                                            <option v-for="(device, deIndex) in devices(datumIndex)" :key="datumIndex+deIndex"
                                            :value="device">{{device.model}}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="form-label mb-1">IMEI / Serial number <span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <select @change="requiredKeyChange(datumIndex)" style="max-width:90px;"
                                                v-model="datum.required_key" class="form-select text-start input-group-text">
                                                <option selected value="imei">IMEI</option>
                                                <option value="serial">SERIAL</option>
                                            </select>
                                            <input v-if="datum.required_key == 'imei'"
                                                @blur="updateCart()" v-model="datum.imei_number"
                                                class="form-control" type="number" placeholder="IMEI number">
                                            <input v-if="datum.required_key == 'serial'"
                                                @blur="updateCart()" v-model="datum.serial_number"
                                                class="form-control" type="text" placeholder="Serial number">
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button v-if="cart.discount.active" @click="addPhone()" type="button" class="btn btn-primary">
                                        Add Another Phone
                                    </button>
                                    <button v-else @click="addPhone()" type="button" class="btn btn-primary">
                                        Need to protect another phone? <br> 
                                        Click here and save an additional 5%
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isMobile" class="row mb-5 justify-content-center">
                    <div class="col-12">
                        <h4 class="">Checkout Summary</h4>
                        <hr class="mb-4 mt-2" /> 
                        <div class="row mb-4 justify-content-center">
                            <div class="col-12">
                                <div class="mb-4 d-flex align-items-center justify-content-center">
                                    <button :class="cart.interval == 'year' ? 'btn-primary' : 'btn-outline-primary'"
                                    @click="intervalChange('year')" type="button" class="btn rounded-0">Yearly</button>
                                    <span class="mx-3">OR</span>
                                    <button :class="cart.interval == 'month' ? 'btn-primary' : 'btn-outline-primary'"
                                    @click="intervalChange('month')" type="button" class="btn rounded-0">Monthly</button>
                                </div>  
                                <checkout-device-cart 
                                :cart="cart" :canSumbmit="canSumbmit"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-5 justify-content-center">
                    <div class="col-12">
                        <h4 class="">Select Your Store</h4>
                        <hr class="mb-4 mt-2" /> 
                        <div class="row mb-4 justify-content-center">
                            <div class="col-12">
                            <label class="form-label mb-1">Select store  <span class="text-danger me-2">*</span></label>
                                <select v-model="store"  class="form-select mb-2"
                                    name="store" placeholder="--select store--">
                                    <option disabled :value="null">--select store--</option>
                                    <option v-for="(store, pIndex) in storeList" :key="pIndex"
                                    :value="store.id">{{store.title}}</option>
                                </select>
                                <small v-if="!store"><strong>NOTE:</strong> You must <router-link to="/stores?create=1"> register store</router-link> before you can register a plan</small>
                            </div>
                        </div>
                    </div>
                </div>            
                <div class="row mb-5 justify-content-center">
                    <div class="col-12">
                        <h4 class="">Payment Information</h4>
                        <hr class="mb-4 mt-2" /> 
                        <div class="row mb-4  justify-content-center">
                            <div class="col-12">
                                <stripe-payment-form
                                ref="stripePaymentForm"
                                :config="config" :showBtn="false"
                                @setupConfirmed="submitData($event)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="">
                            <p class="font-13 my-3"><span class="opacity-6">By clicking "Register now", you agree to the
                                <a :href="absoluteUrl('/partners/terms-conditions')" target="_blank" class='fw-bold'>terms & conditions</a> of morisowireless.</span> </p>
                            <button @click="submitData(setup)" :disabled="!canSumbmit" class="btn px-l btn-lg btn-primary">Register Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 text-center">
                <p>Plans can be cancelled at anytime for a pro-rated refund.<br>
                    Contact <a class="" href="mailto:billing@morisowireless.com">billing@morisowireless.com</a></p>
            </div>
        </div>
    </div>
    <is-loading v-else />
</div>
</template>
<script>
import StripePaymentForm from '@/components/forms/StripePaymentForm.vue';
import CheckoutDeviceCart from '@/components/cards/CheckoutDeviceCart.vue';
import IsLoading from '@/components/IsLoading.vue';

export default {
  name:"device-plan",
  components:{
    StripePaymentForm,
    CheckoutDeviceCart,
    IsLoading
  },
  data(){
    return{
        fetchingCategory: false,
        data:[
            {
                category_id:"",
                brand: "",
                device: "",
                required_key: "imei",
                imei_number: "",
                serial_number: "",
                category: null
            }
        ],
        cart:{
            yearlyBill:0,
            monthlyBill:0,
            discount:{
                total: 0,
                percent: 5,
                factor: 0.05,
                active: false
            },
            interval: "month",
            items:[]
        },
        store: null,
        setup:null,
        canSumbmit: false,
        config:null,
        isLoading: true
    }
  },
  computed:{
    storeList(){
      return this.$store.state.store.list
    },
    categories(){
      return this.$store.state.categories
    },
    brands(){
        return (itemIndex) => {
            const brands = [];
            this.data.forEach((item, index) => {
                if (itemIndex === index && item.category_id) {
                    if(item.category){
                        brands.push(...item.category.brands)
                    }
                }
            }); return brands;
        }
    },
    devices(){
        return (itemIndex) => {
            const devices = [];
            this.data.forEach((item, index) => {
                if (itemIndex === index && item.category_id && item.brand) {
                    if(item.category){
                        devices.push(...item.category.devices.
                        filter(i => i.brand_id === item.brand.id))
                    }
                }
            }); return devices;
        }
    },

  },
  methods: {
      addPhone(){
        this.data.push({ 
            category_id:"",
            brand: "",
            device: "",
            required_key: "imei",
            imei_number: "",
            serial_number: "",
            category: null
        })
        this.cart.discount.active = this.data.length > 1 ? true : false
        this.updateCart()
    },
    removePhone(itemIndex){
        if(!itemIndex) return
        this.data.splice(itemIndex, 1);
        this.cart.discount.active = this.data.length > 1 ? true : false
        this.updateCart()
    },
    async updateCart(){
        this.cart.items = []
        for (const property in this.data) {
            let datum = this.data[property];
            let device = datum.device;
            let brand = datum.brand;

             if(!device || !brand){
                this.canSumbmit = false; return
            }
            if(!(datum.imei_number || datum.serial_number)){
                this.canSumbmit = false; return
            }
            if(datum.required_key == 'imei' && !this.isValidIMEI(datum.imei_number)){
                this.alertError(`The IMEI number of device #${+store + 1} is not valid`); this.canSumbmit = false; return;
            }
            const item = {
                monthly_amount: device.monthly_coverage.price,
                yearly_amount: device.annual_coverage.price,
                interval: this.cart.interval,
                imei_number: datum.imei_number,
                serial_number: datum.serial_number,
                model: device.model,
                make: brand.title,
                device_id: device.id,
                monthly_coverage_id: device.monthly_coverage.id,
                annual_coverage_id: device.annual_coverage.id,
                repair_deductible: device.repair_deductible,
                screen_replacement_deductible: device.screen_replacement_deductible,
            }
            let amount = this.$filters.money_format(item.interval == 'month' ? item.monthly_amount : item.yearly_amount);
            
            item.title = `${item.make} ${item.model} - $${amount} /${item.interval == 'month' ? 'mo':'yr'}`;

            this.cart.items.push(item)
        }
        this.calculateBilling()
    },
    async calculateBilling(){
        let yearlyBill = 0;
        let monthlyBill = 0;
        let totalDiscount = 0;
        let factor = this.cart.discount.active ? this.cart.discount.factor : 0;
        this.cart.items.forEach(item => {
            item.monthly_amount = item.monthly_amount - item.monthly_amount * factor;
            item.yearly_amount = item.yearly_amount - item.yearly_amount * factor;
            totalDiscount = totalDiscount + item.yearly_amount * factor;
            monthlyBill = monthlyBill + item.monthly_amount;
            yearlyBill = yearlyBill + item.yearly_amount;
        });
        this.cart.monthlyBill = monthlyBill;
        this.cart.yearlyBill = yearlyBill;
        this.cart.discount.total = totalDiscount
        this.canSumbmit = true
    },
    storeNotice(){
        Swal.fire({
            title: "Store required!",
            text: "It is required to register stores before proceed.",
            reverseButtons:true,
            showCancelButton: true,
            cancelButtonColor: "#111",
            confirmButtonText: "Register Store",
        }).then((result) => {
            if (result.value) {
                this.$router.push({path: '/stores', query: {create: 1}})
            }
        })
    },
    categoryChange(itemIndex){
        this.data.forEach((item, index) => {
            if(itemIndex === index){
                item.brand = ""
                item.device = ""
                item.imei_number = ""
                item.serial_number = ""
                item.category = ""
            
                this.fetchingCategory = true
                this.$http.get(this.absoluteUrl(`/api/categories/${item.category_id}`))
                    .then((response) => {
                        this.fetchingCategory = false
                        if(response.data.success){
                            item.category = response.data.data
                        }
                })
            }
        })
        this.updateCart()
    },
    brandChange(itemIndex){
        this.data.forEach((item, index) => {
            if(itemIndex === index){
                item.device = ""
                item.imei_number = ""
                item.serial_number = ""
            }
        })
        this.updateCart()
    },
    deviceChange(itemIndex){
        this.data.forEach((item, index) => {
            if(itemIndex === index){
                item.imei_number = ""
                item.serial_number = ""
            }
        })
        this.updateCart()
    },
    requiredKeyChange(itemIndex){
        this.data.forEach((item, index) => {
            if(itemIndex === index){
                item.imei_number = ""
                item.serial_number = ""
            }
        })
        this.updateCart()
    },
    intervalChange(value){
        if(!value) return
        this.cart.interval = value
        this.updateCart()
    },
    submitData(setup){
       this.setup = setup
       if(!this.store){
        this.storeNotice(); return
       }
       if(!this.setup){
        this.$refs.stripePaymentForm.payViaStripe(); return
       }
       let formData = new FormData();
       formData.append('data', JSON.stringify(this.cart));
       formData.append('setup_id', this.setup.id);
       formData.append('store_id', this.store);
       formData.append('payment_method', this.setup.payment_method);
       this.$store.dispatch("changeLoaderValue", true)
       this.$http.post('/subscriptions/create', formData)
       .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
                this.$router.push({path: '/subscriptions'})
            }
       });
    },
    setupPayment(){
        this.isLoading = true
        this.loadStripe()
        this.$http.get(this.absoluteUrl('/api/stripe/setup'))
            .then(response => {
            this.isLoading = false
            if(response.data.success){
                this.config = response.data.data.config
            }
        })
    },
  },
  created(){
    this.$store.dispatch('fetchCategories')
    this.$store.dispatch("store/fetchStores")
    this.setupPayment()    
  }
}
</script>

<style scoped>

</style>