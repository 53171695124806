<template>
  <div class="card mb-0 overflow-hidden rounded-4 border shadow-sm" :class="{'border-success': cart.interval == 'year'}">
    <div v-if="cart.items.length > 1 || cart.interval == 'year'" class="card-header bg-primary text-center text-white">
        <h5 v-if="cart.interval == 'year'" class="text-white mb-0">SAVE ${{(cart.items.length*12)-(cart.items.length - 1)}}!</h5>
        <p v-if="cart.discount.active" class="mb-0 mt-2">+ Extra ${{$filters.money_format(cart.discount.total)}} OFF - Multi-Plan savings!</p>
    </div>
    <div class="card-body text-center p-4 p-md-5 p-lg-6">
        <div :class="{'text-muted': !canSumbmit}" class="fw-bold">
            <span class="fs-6 align-top">$</span>
            <span class="display-5 fw-bold">{{$filters.money_format(cart.interval == 'month' ? cart.monthlyBill : cart.yearlyBill / 12)}}</span>
            <span class="fs-6"> / month </span>
        </div>
        <div class="mt-2 text-muted fw-medium mb-3">
            <span class="fs-6">{{cart.interval == 'year'?'Billed':'Total'}}: </span>
            <span class="fs-7 align-top">$</span> 
            <span class="fs-6"> {{$filters.money_format(cart.interval == 'month' ? cart.monthlyBill*12 : cart.yearlyBill)}}</span> 
            <span class="fs-7"> / year </span>
        </div>
        <h5 class="text-primary mb-0">Device Protection Plan</h5>
        <div v-if="cart.discount.active" class="fw-medium mt-2">5% Multi-plan discount applied</div>
        <div>
            <hr class="border-primary border mx-auto opacity-100" style="max-width:4rem;" />
        </div>
        <div>
            <div v-for="(item, Iindex) in cart.items" :key="Iindex" class="mb-4">
                <h6 class="text-primary mb-3">
                    {{item.title}}
                </h6>
                <div class="text-muted">
                    <h6>DEDICTIBLES</h6>
                    <div class="small">${{item.screen_replacement_deductible}} for screen replacement</div>
                    <div class="small">${{item.repair_deductible}} for repairs</div>
                </div>
            </div>
        </div>
        
        <p class="mb-0"><small>Upgrade or downgrade anytime
            <br> Prorated adjustments</small></p>
    </div>
</div>
</template>

<script>
export default {
    props:{
        cart:{
            type: Object,
            required: true
        },
        canSumbmit:{
            type: Boolean
        }
    }
}
</script>

<style>

</style>